import { initializeApp } from "firebase/app";
import { addDoc, getFirestore, collection, getDocs, serverTimestamp } from "firebase/firestore";




//init

const firebaseConfig = {
  apiKey: "AIzaSyCA1Fe1CRiIMIm0TJFAv9AuUujC9E2FM1I",
  authDomain: "yourdevice-53415.firebaseapp.com",
  databaseURL: "https://yourdevice-53415.firebaseio.com",
  projectId: "yourdevice-53415",
  storageBucket: "yourdevice-53415.appspot.com",
  messagingSenderId: "53012605506",
  appId: "1:53012605506:web:6cdae19df3818568f4dbdb"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);




export const addTestUser = async () => {

  try{
    const docRef = await addDoc(collection(db, "testusers"), {
      first: "Ada",
      last: "Fruit",
      born: 1980,
    });
    console.log(docRef.id);
  }catch(e){
    console.error(e);
  }

};
const add = async ({name, data}) => {
  let success = true;
  let error = null;
  try{
    const docRef = await addDoc(collection(db, name), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
  }catch(e){
    // console.error(e);
    error = e;
    success = false;
  }
  return {
    success: success,
    error: error,
  }
};

export const getTestData = async () => {
  const querySnapshot = await getDocs(collection(db, "test2"));

  querySnapshot.forEach((doc) => {
    console.log(`${doc.id} => ${doc.data()}`);
    for(let key in doc.data()){
      console.log(key, doc.data()[key]);
    }
  });
};

export const sendDeviceData = async ({data}) => {

  console.log("data", data)
  const data_ = {
    os: data.os,
    os_version: data.os_version,
    browser: data.browser,
    model: data.model,
    vendor: data.vendor,
    device: data.device,
    marketing_name: data.marketing_name,
    emulator: data.emulator,
    ua: data.ua,
    width: data.width,
    height: data.height,
  };

  const res = await add({ name: "devices", data: data_});

}