
const ios = [
  {
    Device: "iPhone 12 Pro Max / 13 Pro Max",
    Native_Resolution_Pixels: {
      w: 1284,
      h: 2778
    },
    UIKit_Size_Points: {
      w: 428,
      h: 926
    },
    Native_Scale_factor :"3.0",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone XS Max / 11 Pro Max",
    Native_Resolution_Pixels: {
      w: 1242,
      h: 2688
    },
    UIKit_Size_Points: {
      w: 414,
      h: 896
    },
    Native_Scale_factor :"3.0",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone 12 / 12 Pro / 13 / 13 Pro",
    Native_Resolution_Pixels: {
      w: 1170,
      h: 2532
    },
    UIKit_Size_Points: {
      w: 390,
      h: 844
    },
    Native_Scale_factor :"3.0",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone XR / 11",
    Native_Resolution_Pixels: {
      w: 828,
      h: 1792
    },
    UIKit_Size_Points: {
      w: 414,
      h: 896
    },
    Native_Scale_factor :"2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPhone X / XS / 11 Pro",
    Native_Resolution_Pixels: {
      w: 1125,
      h: 2436
    },
    UIKit_Size_Points: {
      w: 375,
      h: 812
    },
    Native_Scale_factor :"3.0",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone 7 Plus / 8 Plus",
    Native_Resolution_Pixels: {
      w: 1080,
      h: 1920
    },
    UIKit_Size_Points: {
      w: 414,
      h: 736
    },
    Native_Scale_factor: "2.608",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone 6s Plus",
    Native_Resolution_Pixels: {
      w: 1080,
      h: 1920
    },
    UIKit_Size_Points: {
      w: 375,
      h: 667
    },
    Native_Scale_factor: "2.608",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone 6 Plus",
    Native_Resolution_Pixels: {
      w: 1080,
      h: 1920
    },
    UIKit_Size_Points: {
      w: 375,
      h: 667
    },
    Native_Scale_factor: "2.608",
    UIKit_Scale_factor: "3.0",
    max_version: 12
  },
  {
    Device: "iPhone 12 mini / 13 mini",
    Native_Resolution_Pixels: {
      w: 1080,
      h: 2340
    },
    UIKit_Size_Points: {
      w: 375,
      h: 812
    },
    Native_Scale_factor: "3.0",
    UIKit_Scale_factor: "3.0"
  },
  {
    Device: "iPhone 6s / 7 / 8",
    Native_Resolution_Pixels: {
      w: 750,
      h: 1334
    },
    UIKit_Size_Points: {
      w: 375,
      h: 667
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPhone 6",
    Native_Resolution_Pixels: {
      w: 750,
      h: 1334
    },
    UIKit_Size_Points: {
      w: 375,
      h: 667
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0",
    max_version: 12
  },
  {
    Device: "iPhone SE",
    Native_Resolution_Pixels: {
      w: 640,
      h: 1136
    },
    UIKit_Size_Points: {
      w: 320,
      h: 568
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPhone 5S",
    Native_Resolution_Pixels: {
      w: 640,
      h: 1136
    },
    UIKit_Size_Points: {
      w: 320,
      h: 568
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0",
    max_version: 12
  },
  {
    Device: "iPhone 5 / 5c",
    Native_Resolution_Pixels: {
      w: 640,
      h: 1136
    },
    UIKit_Size_Points: {
      w: 320,
      h: 568
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0",
    max_version: 10
  },
  {
    Device: "iPhone 4 / 4S",
    Native_Resolution_Pixels: {
      w: 640,
      h: 960
    },
    UIKit_Size_Points: {
      w: 320,
      h: 480
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPhone 2G / 3G / 3GS",
    Native_Resolution_Pixels: {
      w: 320,
      h: 480
    },
    UIKit_Size_Points: {
      w: 320,
      h: 480
    },
    Native_Scale_factor: "1.0",
    UIKit_Scale_factor: "1.0"
  },
  {
    Device: "iPad Pro 12.9-inch",
    Native_Resolution_Pixels: {
      w: 2048,
      h: 2732
    },
    UIKit_Size_Points: {
      w: 1024,
      h: 1366
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad / iPad 2",
    Native_Resolution_Pixels: {
      w: 768,
      h: 1024
    },
    UIKit_Size_Points: {
      w: 768,
      h: 1024
    },
    Native_Scale_factor: "1.0",
    UIKit_Scale_factor: "1.0"
  },
  {
    Device: "iPad 7th gen",
    Native_Resolution_Pixels: {
      w: 1620,
      h: 2160
    },
    UIKit_Size_Points: {
      w: 810,
      h: 1080
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad Pro 10.5-inch / iPad Air 3rd gen",
    Native_Resolution_Pixels: {
      w: 1668,
      h: 2224
    },
    UIKit_Size_Points: {
      w: 834,
      h: 1112
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad Air 4th gen",
    Native_Resolution_Pixels: {
      w: 1640,
      h: 2360
    },
    UIKit_Size_Points: {
      w: 820,
      h: 1180
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad Pro 11",
    Native_Resolution_Pixels: {
      w: 1668,
      h: 2388
    },
    UIKit_Size_Points: {
      w: 834,
      h: 1194
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad 3/ 4 / Air / Air 2 / Pro (9.7-inch)",
    Native_Resolution_Pixels: {
      w: 1536,
      h: 2048
    },
    UIKit_Size_Points: {
      w: 768,
      h: 1024
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad Mini",
    Native_Resolution_Pixels: {
      w: 768,
      h: 1024
    },
    UIKit_Size_Points: {
      w: 768,
      h: 1024
    },
    Native_Scale_factor: "1.0",
    UIKit_Scale_factor: "1.0"
  },
  {
    Device: "iPad Mini 2 / 3 / 4 / 5th gen",
    Native_Resolution_Pixels: {
      w: 1536,
      h: 2048
    },
    UIKit_Size_Points: {
      w: 768,
      h: 1024
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  },
  {
    Device: "iPad Mini 6th gen",
    Native_Resolution_Pixels: {
      w: 1488,
      h: 2266
    },
    UIKit_Size_Points: {
      w: 744,
      h: 1133
    },
    Native_Scale_factor: "2.0",
    UIKit_Scale_factor: "2.0"
  }

];

const versions = [
  {
    name: "1.20.1",
    description: "レポートにwidth/heightの表示追加",
    date: "2024-08-02"
  },
  {
    name: "1.19.2",
    description: "Mac ChromeのUserAgent処理修正",
    date: "2023-08-16"
  },
  {
    name: "1.19.1",
    description: "Android ChromeのUserAgent処理修正",
    date: "2023-08-15"
  },
  {
    name: "1.18.0",
    description: "iOS16の表示バグ修正",
    date: "2023-08-03"
  },
  {
    name: "1.17.0",
    description: "レポートにPCのアクセス数も追加",
    date: "2022-09-03"
  },
  {
    name: "1.16.0",
    description: "iPhone 13 に対応",
    date: "2021-10-20"
  },
  {
    name: "1.14.0",
    description: "Contact ページを改修",
    date: "2020-07-11"
  },
  {
    name: "1.13.0",
    description: "項目の説明を追加",
    date: "2020-05-14"
  },
  {
    name: "1.12.1",
    description: "英語ページの追加",
    date: "2020-04-19"
  },
  {
    name: "1.10.0",
    description: "iPhoneのARCore対応判定追加",
    date: "2020-02-22"
  },
  {
    name: "1.9.0",
    description: "AndroidのARCore対応判定追加",
    date: "2020-02-11"
  },
  {
    name: "1.8.1",
    description: "iPhone 6 の判定を修正",
    date: "2020-02-08"
  },
  {
    name: "1.8.0",
    description: "iPhone 6 の判定を追加",
    date: "2020-02-01"
  },
  {
    name: "1.7.1",
    description: "iOSのエミュレータの判定を追加",
    date: "2019-10-03"
  },
  {
    name: "1.7.0",
    description: "エミュレータの判定を追加",
    date: "2019-10-02"
  },
  {
    name: "1.6.0",
    description: "iPhone11系対応",
    date: "2019-09-26"
  },
  {
    name: "1.5.0",
    description: "ロゴアイコン追加",
    date: "2019-09-21"
  },
  {
    name: "1.4.0",
    description: "広告追加",
    date: "2019-08-04"
  },
  {
    name: "1.3.0",
    description: "問い合わせページ追加",
    date: "2019-07-28"
  },
  {
    name: "1.3.0",
    description: "依存ライブラリアップデート",
    date: "2019-07-17"
  },
  {
    name: "1.1.0",
    description: "軽微な修正",
    date: "2019-07-06"
  },
  {
    name: "1.0.0",
    description: "正式版リリース",
    date: "2019-05-22"
  },
  {
    name: "0.18.0 (β版)",
    description: "UserAgentの判定ロジックの修正",
    date: "2019-04-21"
  },
  {
    name: "0.17.0 (β版)",
    description: "UserAgentのコピーボタンの追加",
    date: "2019-04-18"
  },
  {
    name: "0.16.0 (β版)",
    description: "言語を追加",
    date: "2019-04-08"
  },
  {
    name: "0.15.0 (β版)",
    description: "色深度を追加",
    date: "2019-03-16"
  },
  {
    name: "0.14.0 (β版)",
    description: "スクリーンの向きを追加",
    date: "2019-03-16"
  },
  {
    name: "0.13.0 (β版)",
    description: "充電情報の修正",
    date: "2019-03-14"
  },
  {
    name: "0.12.0 (β版)",
    description: "充電情報の追加",
    date: "2019-03-13"
  },
  {
    name: "0.11.0 (β版)",
    description: "Androidの端末不明の場合の処理追加",
    date: "2019-03-12"
  },
  {
    name: "0.10.0 (β版)",
    description: "免責事項等はprivacyページへ",
    date: "2019-01-29"
  },
  {
    name: "0.9.2 (β版)",
    description: "更新履歴ページとサイドメニューの追加",
    date: "2019-01-26"
  },
  {
    name: "0.8.0 (β版)",
    description: "バージョン表示の修正",
    date: "2019-01-24"
  },
  {
    name: "0.7.0 (β版)",
    description: "日本語表示項目の追加",
    date: "2019-01-23"
  },
  {
    name: "0.6.0 (β版)",
    description: "表示項目の追加",
    date: "2019-01-21"
  },
  {
    name: "0.5.1 (β版)",
    description: "FireFoxの不具合修正",
    date: "2019-01-18"
  },
  {
    name: "0.4.1 (β版)",
    description: "iOSの不具合修正",
    date: "2019-01-18"
  },
  {
    name: "0.4.0 (β版)",
    description: "表示項目の追加",
    date: "2019-01-17"
  },
  {
    name: "0.3.0 (β版)",
    description: "判定できないAndroid端末のバグ修正",
    date: "2019-01-17"
  },
  { 
    name: "0.2.0 (β版)",
    description: "Androidの表示バグ修正",
    date: "2019-01-15"
  },
  {
    name: "0.1.0 (β版)",
    description: "公開開始",
    date: "2019-01-14"
  }
];

exports.language = "ja";
exports.ios = ios;
exports.versions = versions;

//参考
//https://developer.apple.com/library/archive/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/Displays/Displays.html
//https://qiita.com/TD3P/items/b3b754bde584abc98f44

