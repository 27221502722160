import uaparser from "ua-parser-js";
import axios from "axios";
import csvtojson from "csvtojson";
import UIkit from 'uikit';
import Icons from "uikit/dist/js/uikit-icons.min.js";


import Vue from "vue";
import VueI18n from "vue-i18n";
import ja from "../i18n/ja.json";
import en from "../i18n/en.json";


import * as firebase from "firebase/app";

import { sendDeviceData } from "./db.js";

const ANDROID_DEVICES_CSV_URLS = ["/files/supported_devices.csv"];
const AR_CORE_DEVICES_CSV_URL = "/files/arcore_devices.csv";

import settings from "./settings.js"
import { version } from "../../package.json";

UIkit.use(Icons);

(function(global, gtag){

  global.ua = uaparser;
  global.fb = firebase;

  Vue.use(VueI18n);

  const ua = uaparser();

  const ios_list = settings.ios;
  const versions = settings.versions;

  const isTopPage = function(){
    const app = document.getElementById("app");
    if(app){
      return true;
    }
  };

  const isContactPage = function(){
    const app = document.getElementById("contact");
    if(app){
      return true;
    }
  };

  const getData = function(path, head){
    return new Promise(function(resolve, reject){
      axios.get(path)
        .then(function (response) {
          const csv_data = head ? head + response.data : response.data;
          csvtojson({
            // output: "csv"
          })
          .fromString(csv_data)
          .then(function(csv_arr){
            resolve(csv_arr);
          });
        })
        .catch(function (error) {
          console.log(error);
          reject();
        })
        .then(function () {
        });
    });
  };

  const searchAndroid = function(list, device, ua){

    return new Promise(function(resolve, reject){
      let res = list.filter(function(elm){
        if(elm["Model"] === device.model){
          return true;
        }
        if(elm["Model"].includes(device.model)){
          return true;
        }
      })[0];

      if(res === undefined){
        let temp = ua.ua.match(/android.*?\;\s([a-z0-9_-]*).*\)/i);
        let model = "";
        if(temp && temp.length && temp.length > 1 ){
          model = temp[1];
        }
        // console.info(model);
        res = list.filter(function(elm){
          if(elm["Model"] === model){
            return true;
          }
          if(elm["Model"].includes(model)){
            return true;
          }
        })[0];

        if(!res){
          res = { Model: model};
        }
        // console.info(res);

      }

      resolve(res);

    });
  };

  const searchIOS = function(list, window, ua){
    const version = ua.os?.version;
    if(!version){
      return null;
    }
    const major_version = Number(version.split(".")[0]);

    let res = list.filter(function(elm){
      if(elm.UIKit_Size_Points.w === window.screen.availWidth && elm.UIKit_Size_Points.h === window.screen.availHeight){
        return true;
      }
    });
    if(res.length > 1){
      res = res.filter(function(elm){
        return Number(elm.UIKit_Scale_factor) === window.devicePixelRatio;
      });
    }

    let temp = res;
    if(res.length > 1){
      temp = res.filter(function(elm){
        return Number(elm.max_version) >= major_version;
      });
    }
    if(temp.length === 0){
      return res[0];
    }else{
      return temp[0];
    }
  };

  const searchARCore = function({android, ios, list}){
    return new Promise(function(resolve, reject){
      let filtered;
      if(!ios){
        filtered = list.filter(function(elm){
          if(elm["Model"] === android["Model"]){
            return true;
          }
          if(elm["Model"].includes(android["Model"])){
            return true;
          }
        });

        if(filtered.length > 1){
          filtered = list.filter(function(elm){
            if(elm["Manufacturer"] === android["Retail Branding"]){
              return true;
            }
            if(elm["Manufacturer"].includes(android["Retail Branding"])){
              return true;
            }
          });
        }

        if(filtered){
          return resolve(filtered[0]);
        }
        return resolve();
      }else{
        filtered = list.filter(function(elm){
          return /iPhone/.test(elm["Model"]);
        });
        const devices = ios.Device.split("/");
        
        for(let i = 0; i < filtered.length; i++){
          const f = filtered[i]["Model"].replace("iPhone", "").trim();
          for(let j = 0; j < devices.length; j++){
            const d = devices[j].replace("iPhone", "").trim();
            // console.info(f," : ", d);
            if(f === d){
              return resolve(f);
            }
          }
        }

      }
      return resolve();
    });
  };


  var makeApp = function(obj){

    const messages = {
      ja: ja,
      en: en
    };

    const lang = document.querySelector("body").dataset.lang;

    const i18n = new VueI18n({
      locale: lang,
      messages,
    });


    new Vue({
      el: "#app",
      i18n: i18n,
      data: {
        versions: versions,
        ua: obj.ua,
        res:{
          android: obj.android,
          ios: obj.ios
        },
        screen: global.screen,
        navigator: global.navigator,
        battery: "",
        inited: false,
        arcore: obj.arcore,
        current_language: "",
      },
      computed: {
        pixelRatio: function(){
          return global.devicePixelRatio;
        },
        deviceMemory: function(){
          const mem = this.navigator.deviceMemory;
          if(mem){
            let str = mem + " GB";
            if(mem >= 8){
              str = `${str} ${this.$t("message.ormore")}`;
            }
            return str;
          }
        },
        hardwareConcurrency: function(){
          if(this.navigator.hardwareConcurrency){
            return this.navigator.hardwareConcurrency + " Core";
          }
        },
        maxTouchPoints: function(){
          return this.navigator.maxTouchPoints;
        },
        platform: function(){
          return this.navigator.platform;
        },
        connection: function(){
          if(this.navigator.connection){
            return this.navigator.connection;
          }
        },
        charging: function(){
          if(this.battery && this.battery.charging){
            return "Yes";
          }else{
            return "No";
          }
        },
        chargingTime: function(){
          let res = "-";
          if(this.battery && this.battery.chargingTime){
            res = this.battery.chargingTime;
          }
          if(res === Infinity){
            res = "-";
          }
          return res;
        },
        batteryLevel: function(){
          if(this.battery && this.battery.level){
            return this.battery.level * 100;
          }else{
            return "-";
          }
        },
        exitsARCore: function(){
          if(this.arcore){
            return true;
          }
          return false;
        },
        existsARCoreNotes: function(){
          if(this.arcore && this.arcore["Notes"]){
            return true;
          }
          return false;
        },
        arCoreNotes: function(){
          if(this.arcore && this.arcore["Notes"]){
            return this.arcore["Notes"];
          }
          return "";
        }
      },
      methods: {
        isMobile: function(ua){
          if(this.isIOS(ua) || this.isAndroid(ua)){
            return true;
          }
        },
        isIOS: function(ua){
          if(this.ua.os.name === "iOS"){
            return true;
          }
        },
        isIPhone: function(ua){
          if(this.ua.device.model === "iPhone"){
            return true;
          }
        },
        isIPad: function(ua){
          if(this.ua.device.model === "iPad"){
            return true;
          }
        },
        isAndroid: function(ua){
          if(this.ua.os.name === "Android"){
            return true;
          }
        },
        isEmulator: function(){
          if(/win32/i.test(this.platform)){
            return true;
          }
          if(/MacIntel/i.test(this.platform)){
            return true;
          }
        },
        getAndroid: function(type){
          if(this.res.android && this.res.android[type]){
            return this.res.android[type];
          }
        },
        getVersionName: function(version, index){
          const name = version.name;
          if(index === 0){
            return name + " [最新版]";
          }else{
            return name;
          }
        },
        getIfExist: function(parent, child){
          if(parent && parent[child]){
            return parent[child];
          }
        },
        getParseAndValueOfMemory: function(val){


        },
        getParseAndValue: function(val){
          let res;
          if(val){
            if(String(val).match(/^\s*$/)){
              res = "-";
            }else{
              res = val;
            }
          }else{
            res = "-";
          }
          return res;
        },
        getParseAndValueFromArray: function(arr){
          let first;
          let res;
          try{
            for(let i = 0; i < arr.length; i++){
              if(i === 0){
                first = arr[i];
                res = first;
              }else{
                res = res[arr[i]];
              }
            }
          }catch(err){
            console.log(err);
            res = "";
          }
          return this.getParseAndValue(res);
        },
        getRenderingEngine: function(engine){
          const name = engine.name;
          const version = engine.version;
          if(!version){
            return this.getParseAndValue(name);
          }else{
            return this.getParseAndValue(name + " " + version);

          }
        },
        sendGaEvent: function(){
          let label = "";
          const emu = this.isEmulator() ? "true" : "false";
          if(this.isAndroid()){
            label = "Model:" + this.getAndroid("Model") + "; RetailBranding:" + this.getAndroid("Retail Branding") + "; Device:" + this.getAndroid("Device") + "; Marketing Name:" + this.getAndroid("Marketing Name") + "; ua:" + this.ua.ua + "; Emulator:" + emu;
          }else if(this.isIOS()){
            const res_ios_dev = this.res.ios ? this.res.ios["Device"] : "undefined";
            label = "Model:" +  this.ua.device.model + "; Vendor:" + this.ua.device.vendor + " ;Device:" + res_ios_dev + "; ua:" + this.ua.ua + "; Emulator:" + emu;
          }else{
            label = "PC" + "; ua:" + this.ua.ua;
          }
          gtag('event', "display", {
            'event_category': "device_info",
            'event_label':label,
            'value': 0
          });
        },
        sendDeviceData: function(){
          const data = {
            os: "",
            os_version: "",
            browser: "",
            model: "",
            vendor: "",
            device: "",
            marketing_name: "",
            emulator: "",
            width: "",
            height: "",
          };

          const emu = this.isEmulator() ? true : false;
          data.os = this.ua.os.name;
          data.os_version = this.ua.os.version;
          data.browser = ua.browser.name;
          data.emulator = emu;
          data.ua = this.ua.ua;
          if(this.isAndroid()){
            data.os = "Android";
            data.model = this.getAndroid("Model");
            data.vendor = this.getAndroid("Retail Branding");
            data.device = this.getAndroid("Device");
            data.marketing_name = this.getAndroid("Marketing Name");
          }else if(this.isIOS()){
            const res_ios_dev = this.res.ios ? this.res.ios["Device"] : "undefined";
            data.os = "iOS";
            data.model = this.ua.device.model;
            data.vendor = this.ua.device.vendor;
            data.device =  res_ios_dev;
          }

          if(window.screen.availWidth){
            data.width = window.screen.availWidth + "px";
          }
          if(window.screen.availHeight){
            data.height = window.screen.availHeight + "px";
          }
          sendDeviceData({data});
        },
        getBattery: function(){
          const self = this;
          if(self.navigator.getBattery){
            self.navigator.getBattery()
                          .then(function(data){
                            self.battery = data;
                          });
          }else{
            self.battery = "";
          }
        },
        showToolTip: function(evt){
          console.info(evt);
        },
        copyUA: function(evt){
          const elm = this.$refs.ua;
          document.getSelection().selectAllChildren(elm);
          const result = document.execCommand("copy");
          document.getSelection().empty();

          const t = UIkit.tooltip(evt.target, {title: this.$t("message.copied_it")});
          t.show();
          setTimeout(function(){
            t.hide();
            UIkit.disconnect(evt.target);
          }, 1000)

        },
        init: function(){
          this.inited = true;
        }
      },
      mounted: function(){
        this.sendGaEvent();
        this.sendDeviceData();
        this.getBattery();
        this.init();

      }
    });
  };


  const executeProcess = async function(device, ua){

    const isLinuxAndroid10k = function(){
      if(ua.ua.includes("(Linux; Android 10; K)")){
        return true;
      }
      return false;
    };
  
    const hasUADGetHighEntropyValues = function(){
      return window?.navigator?.userAgentData?.getHighEntropyValues ? true : false;
    };

    const isChrome = function(){
      if(ua.browser.name === "Chrome"){
        return true;
      }
      return false;
    };

    const isMacOS = function(){
      return ua.os.name.match(/mac\s{0,1}os/i) ? true : false;
    };
  
    const isAndroidChrome = function(){
      return isLinuxAndroid10k() && isChrome();
    };

    const isMacChrome = function(){
      return isChrome() && isMacOS();
    };

    const overWriteUA = async function(device, ua){

      const oldObj = {
        newDevice: device,
        newUa: ua,
      }
      if(( isAndroidChrome() || isMacChrome() ) && hasUADGetHighEntropyValues()){

        const res = await navigator.userAgentData.getHighEntropyValues([
          "architecture",
          "bitness",
          "model",
          "platformVersion",
          "fullVersionList"
        ])
          .catch(err => {
            return {
              error: err
            };
          })
          .then(res => {
            return res;
          });
        
        if(res.error){
          return oldObj;
        }

        const newUa = { ...ua };

        const model = res.model;
        const browser = res.fullVersionList.find( elm => {
          if(elm.brand === "Google Chrome"){
            return true;
          }
          return false;
        } );
        const browserVersion = browser?.version;
        const platformVersion = res.platformVersion;
        const architecture = res.architecture;

        newUa.browser.version = browserVersion ? browserVersion : newUa.browser.version;
        newUa.cpu.architecture = architecture ? architecture : newUa.cpu.architecture;
        newUa.device.model = model ? model : newUa.device.model;
        // newUa.engine.version = browserVersion ? browserVersion : newUa.engine.version;
        newUa.os.version = platformVersion ? platformVersion : newUa.os.version;

        // newUa.

        return {
          newDevice: newUa.device,
          newUa: newUa,
        }

      }

      return oldObj;

    };

    const { newDevice, newUa} = await overWriteUA(device, ua);

    console.log(newUa);

    const android_list = await getData(ANDROID_DEVICES_CSV_URLS[0]);

    const android_result = await searchAndroid(android_list, newDevice, newUa);

    const arcore_list = await getData(AR_CORE_DEVICES_CSV_URL);


    const ios_result = searchIOS(ios_list, global, ua);

    const arcore_result = await searchARCore({
      android: android_result,
      ios: ios_result,
      list: arcore_list
    });


    makeApp({
      android: android_result,
      ios: ios_result,
      ua: ua,
      arcore: arcore_result
    });
  };

  const makeContact = function(){
    new Vue({
      el: "#contact",
      data: {
        names: {
          name: "名前",
          ph_name: "名前",
          email: "メールアドレス",
          ph_email: "abc@example.com",
          contents: "内容",
          ph_contents: "お問い合わせ内容を入力してください"
        },
        messages: {
          do_you_send: "問い合わせを送信しますか？",
          complete: "お問い合わせありがとうございます。送信完了しました",
          error: "送信に失敗しました。時間をおいて再度お試しください",
          need_input: "項目を入力してください"
        },
        fbinit: "",
        name: "",
        email: "",
        contents: ""
      },
      computed: {

      },
      methods: {
        submitForm: function(){
          return false;
        },
        sendMail: function(){
          if(!this.email || !this.contents){
            global.alert(this.messages.need_input);
            return false;
          }

          const do_you_send = global.confirm(this.messages.do_you_send);

          if(!this.fbinit){
            this.fbinit = firebase.initializeApp({
              apiKey: 'AIzaSyCA1Fe1CRiIMIm0TJFAv9AuUujC9E2FM1I',
              authDomain: 'yourdevice.tanokatu.com',
              projectId: 'yourdevice-53415'
            });
          }
          const sendobj = {
            name: this.name,
            email: this.email,
            contents: this.contents
          };

          const mailer = firebase.functions().httpsCallable('sendMail');
          mailer(sendobj)
            .then(() => {
              global.alert(this.messages.complete);
            })
            .catch(err => {
              console.log(err);
              global.alert(this.messages.error);
            })
            .finally(() => {
              console.log("finally");
            });

        }

      },
      mounted: function(){

      }
    });

  };

  const makeContactWithoutForm = function(){
    new Vue({
      el: "#contact",
      data: {
        email: "syagawapp@gmail.com"
      },
      methods: {
        drawCanvas(){
          const ctx = this.$refs.canvas.getContext('2d');
          ctx.font = "20px serif";
          ctx.fillText(this.email, 0, 20);
        }
      },
      mounted(){
        this.drawCanvas();
      }
    });
  };

  if(isTopPage()){
    executeProcess(ua.device, ua);
  }else if(isContactPage()){
    // makeContact();
    makeContactWithoutForm();
  }

})(window, gtag);
